const strategies = [
    { id: 1, description: "Only snipers" },
    { id: 2, description: "Only assault legends" },
    { id: 3, description: "Only one POI per person" },
    { id: 4, description: "No armor challenge" },
    { id: 5, description: "Melee only" },
    { id: 6, description: "No backpack challenge" },
    { id: 7, description: "Pistols only" },
    { id: 8, description: "Can't use the same vehicle twice" },
    { id: 9, description: "One weapon type per squad" },
    { id: 10, description: "Use only green items" },
    { id: 11, description: "No healing during fights" },
    { id: 12, description: "Shotguns only" },
    { id: 13, description: "Ultimate abilities only" },
    { id: 14, description: "No abilities challenge" },
    { id: 15, description: "Reverse controls challenge" },
    { id: 16, description: "No minimap usage" },
    { id: 17, description: "Swap gear with every kill" },
    { id: 18, description: "First gun challenge - use only the first gun you find" },
    { id: 19, description: "Communicate using only ping system" },
    { id: 20, description: "Grenades only" },
    { id: 21, description: "Only use care package weapons" },
    { id: 22, description: "Healing items only from lifeline packages" },
    { id: 23, description: "Only can kill with environmental hazards" },
    { id: 24, description: "No jumping during fights" },
    { id: 25, description: "Random legend selection" },
    { id: 26, description: "Every squad member uses a different map quadrant" },
    { id: 27, description: "Only use weapons found in Hot Zones" },
    { id: 28, description: "Silenced weapons only" },
    { id: 29, description: "No sprinting allowed" },
    { id: 30, description: "No reviving teammates" },
    { id: 31, description: "Finishers only to confirm kills" },
    { id: 32, description: "Only energy weapons" },
    { id: 33, description: "Only use zip lines for movement" },
    { id: 34, description: "No weapon attachments" },
    { id: 35, description: "No ultimate abilities" },
    { id: 36, description: "Only loot death boxes for gear" },
    { id: 37, description: "No recharging shields" },
    { id: 38, description: "Only LMGs" },
    { id: 39, description: "Only use loot from supply bins" },
    { id: 40, description: "Only SMGs" },
    { id: 41, description: "Play without HUD" },
    { id: 42, description: "Switch weapons with squadmates every 5 minutes" },
    { id: 43, description: "Only use throwable items" },
    { id: 44, description: "No looting after the first 3 minutes" },
    { id: 45, description: "Always stay in the ring" },
    { id: 46, description: "Cannot use doors" },
    { id: 47, description: "Only use legends with no tactical abilities" },
    { id: 48, description: "Play using only voice commands" },
    { id: 49, description: "All squad members must wear headphones backwards" },
    { id: 50, description: "Only one magazine per gun" },
    { id: 51, description: "Limit to two inventory slots" },
    { id: 52, description: "Only fight while crouching" },
    { id: 53, description: "Never use the same hiding spot twice" },
    { id: 54, description: "Every kill must be followed by a taunt" },
    { id: 55, description: "Only one legend type per squad" },
    { id: 56, description: "Mandatory weapon swap after each kill" },
    { id: 57, description: "No healing outside the ring" },
    { id: 58, description: "Rotate squad leader every match" },
    { id: 59, description: "No recon legends" },
    { id: 60, description: "Only holographic sights" },
    { id: 61, description: "Reverse ammo types (light weapons use heavy ammo)" },
    { id: 62, description: "Play blindfolded with guidance from a teammate" },
    { id: 63, description: "Use only single fire mode" },
    { id: 64, description: "No consumables" },
    { id: 65, description: "Rotate dropping zones clockwise each game" },
    { id: 66, description: "Every squad member must solo a different team" },
    { id: 67, description: "Only kill leaders can deal damage" },
    { id: 68, description: "Use only Evo Shields" },
    { id: 69, description: "No landing in named locations" },
    { id: 70, description: "Use each supply bin only once" },
    { id: 71, description: "Randomize sensitivity settings each game" },
    { id: 72, description: "No Pathfinder's grappling hook" },
    { id: 73, description: "Only allowed to heal teammates, not self" },
    { id: 74, description: "Every squad member plays as a support character" },
    { id: 75, description: "Only use shotguns and pistols" },
    { id: 76, description: "No crouching allowed" },
    { id: 77, description: "Squad must always be in different sectors" },
    { id: 78, description: "Limit one kill per weapon" },
    { id: 79, description: "Every player must drop their entire inventory upon being pinged" },
    { id: 80, description: "Only loot enemies, no loot from the ground" },
    { id: 81, description: "Use only weapons with alternate fire modes" },
    { id: 82, description: "Drop all loot when shield breaks" },
    { id: 83, description: "No respawning teammates" },
    { id: 84, description: "Only Mirage clones for first 3 minutes" },
    { id: 85, description: "Landing must be at the furthest point from the flight path" },
    { id: 86, description: "No standing still during engagements" },
    { id: 87, description: "Use only Bangalore's smoke for cover" },
    { id: 88, description: "No kills, only assists allowed" },
    { id: 89, description: "Only use the ring's edge to fight" },
    { id: 90, description: "Rotate weapons between squad members every round" },
    { id: 91, description: "Each player must end the game with different types of grenades" },
    { id: 92, description: "Must engage every enemy seen" },
    { id: 93, description: "No weapons challenge - melee and abilities only" },
    { id: 94, description: "Only use Mozambique" },
    { id: 95, description: "Win without using any abilities" },
    { id: 96, description: "Each squad member must carry a different type of ammo" },
    { id: 97, description: "No tactical abilities during final round" },
    { id: 98, description: "Use only burst fire weapons" },
    { id: 99, description: "Cannot use vehicles" },
    { id: 100, description: "Must win a fight before looting each new area" },
];

export default strategies;