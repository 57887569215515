const baseChamps = [
    { id: 1, name: 'Aatrox', class: 'Fighter', image: require('../leagueChampionPhotos/Aatrox.webp') },
    { id: 2, name: 'Ahri', class: 'Mage', image: require('../leagueChampionPhotos/Ahri.webp') },
    { id: 3, name: 'Akali', class: 'Assassin', image: require('../leagueChampionPhotos/Akali.webp') },
    { id: 4, name: 'Akshan', class: 'Marksman', image: require('../leagueChampionPhotos/Akshan.webp') },
    { id: 5, name: 'Alistar', class: 'Tank', image: require('../leagueChampionPhotos/Alistar.webp') },
    { id: 6, name: 'Amumu', class: 'Tank', image: require('../leagueChampionPhotos/Amumu.webp') },
    { id: 7, name: 'Anivia', class: 'Mage', image: require('../leagueChampionPhotos/Anivia.webp') },
    { id: 8, name: 'Annie', class: 'Mage', image: require('../leagueChampionPhotos/Annie.webp') },
    { id: 9, name: 'Aphelios', class: 'Marksman', image: require('../leagueChampionPhotos/Aphelios.webp') },
    { id: 10, name: 'Ashe', class: 'Marksman', image: require('../leagueChampionPhotos/Ashe.webp') },
    { id: 11, name: 'Aurelion Sol', class: 'Mage', image: require('../leagueChampionPhotos/AurelionSol.webp') },
    { id: 12, name: 'Azir', class: 'Mage', image: require('../leagueChampionPhotos/Azir.webp') },
    { id: 13, name: 'Bard', class: 'Support', image: require('../leagueChampionPhotos/Bard.webp') },
    { id: 14, name: 'Blitzcrank', class: 'Support', image: require('../leagueChampionPhotos/Blitzcrank.webp') },
    { id: 15, name: 'Brand', class: 'Mage', image: require('../leagueChampionPhotos/Brand.webp') },
    { id: 16, name: 'Braum', class: 'Support', image: require('../leagueChampionPhotos/Braum.webp') },
    { id: 17, name: 'Caitlyn', class: 'Marksman', image: require('../leagueChampionPhotos/Caitlyn.webp') },
    { id: 18, name: 'Camille', class: 'Fighter', image: require('../leagueChampionPhotos/Camille.webp') },
    { id: 19, name: 'Cassiopeia', class: 'Mage', image: require('../leagueChampionPhotos/Cassiopeia.webp') },
    { id: 20, name: 'Chogath', class: 'Tank', image: require('../leagueChampionPhotos/Chogath.webp') },
    { id: 21, name: 'Corki', class: 'Marksman', image: require('../leagueChampionPhotos/Corki.webp') },
    { id: 22, name: 'Darius', class: 'Fighter', image: require('../leagueChampionPhotos/Darius.webp') },
    { id: 23, name: 'Diana', class: 'Fighter', image: require('../leagueChampionPhotos/Diana.webp') },
    { id: 24, name: 'Draven', class: 'Marksman', image: require('../leagueChampionPhotos/Draven.webp') },
    { id: 25, name: 'Dr. Mundo', class: 'Tank', image: require('../leagueChampionPhotos/DrMundo.webp') },
    { id: 26, name: 'Ekko', class: 'Assassin', image: require('../leagueChampionPhotos/Ekko.webp') },
    { id: 27, name: 'Elise', class: 'Mage', image: require('../leagueChampionPhotos/Elise.webp') },
    { id: 28, name: 'Evelynn', class: 'Assassin', image: require('../leagueChampionPhotos/Evelynn.webp') },
    { id: 29, name: 'Ezreal', class: 'Marksman', image: require('../leagueChampionPhotos/Ezreal.webp') },
    { id: 30, name: 'Fiddlesticks', class: 'Mage', image: require('../leagueChampionPhotos/FiddleSticks.webp') },
    { id: 31, name: 'Fiora', class: 'Fighter', image: require('../leagueChampionPhotos/Fiora.webp') },
    { id: 32, name: 'Fizz', class: 'Assassin', image: require('../leagueChampionPhotos/Fizz.webp') },
    { id: 33, name: 'Galio', class: 'Tank', image: require('../leagueChampionPhotos/Galio.webp') },
    { id: 34, name: 'Gangplank', class: 'Fighter', image: require('../leagueChampionPhotos/Gangplank.webp') },
    { id: 35, name: 'Garen', class: 'Fighter', image: require('../leagueChampionPhotos/Garen.webp') },
    { id: 36, name: 'Gnar', class: 'Fighter', image: require('../leagueChampionPhotos/Gnar.webp') },
    { id: 37, name: 'Gragas', class: 'Fighter', image: require('../leagueChampionPhotos/Gragas.webp') },
    { id: 38, name: 'Graves', class: 'Marksman', image: require('../leagueChampionPhotos/Graves.webp') },
    { id: 39, name: 'Hecarim', class: 'Fighter', image: require('../leagueChampionPhotos/Hecarim.webp') },
    { id: 40, name: 'Heimerdinger', class: 'Mage', image: require('../leagueChampionPhotos/Heimerdinger.webp') },
    { id: 41, name: 'Illaoi', class: 'Fighter', image: require('../leagueChampionPhotos/Illaoi.webp') },
    { id: 42, name: 'Irelia', class: 'Fighter', image: require('../leagueChampionPhotos/Irelia.webp') },
    { id: 43, name: 'Ivern', class: 'Support', image: require('../leagueChampionPhotos/Ivern.webp') },
    { id: 44, name: 'Janna', class: 'Support', image: require('../leagueChampionPhotos/Janna.webp') },
    { id: 45, name: 'Jarvan IV', class: 'Tank', image: require('../leagueChampionPhotos/JarvanIV.webp') },
    { id: 46, name: 'Jax', class: 'Fighter', image: require('../leagueChampionPhotos/Jax.webp') },
    { id: 47, name: 'Jayce', class: 'Fighter', image: require('../leagueChampionPhotos/Jayce.webp') },
    { id: 48, name: 'Jhin', class: 'Marksman', image: require('../leagueChampionPhotos/Jhin.webp') },
    { id: 49, name: 'Jinx', class: 'Marksman', image: require('../leagueChampionPhotos/Jinx.webp') },
    { id: 50, name: 'Kaisa', class: 'Marksman', image: require('../leagueChampionPhotos/Kaisa.webp') },
    { id: 51, name: 'Kalista', class: 'Marksman', image: require('../leagueChampionPhotos/Kalista.webp') },
    { id: 52, name: 'Karma', class: 'Mage', image: require('../leagueChampionPhotos/Karma.webp') },
    { id: 53, name: 'Karthus', class: 'Mage', image: require('../leagueChampionPhotos/Karthus.webp') },
    { id: 54, name: 'Kassadin', class: 'Assassin', image: require('../leagueChampionPhotos/Kassadin.webp') },
    { id: 55, name: 'Katarina', class: 'Assassin', image: require('../leagueChampionPhotos/Katarina.webp') },
    { id: 56, name: 'Kayle', class: 'Fighter', image: require('../leagueChampionPhotos/Kayle.webp') },
    { id: 57, name: 'Kayn', class: 'Fighter', image: require('../leagueChampionPhotos/Kayn.webp') },
    { id: 58, name: 'Kennen', class: 'Mage', image: require('../leagueChampionPhotos/Kennen.webp') },
    { id: 59, name: 'Khazix', class: 'Assassin', image: require('../leagueChampionPhotos/Khazix.webp') },
    { id: 60, name: 'Kindred', class: 'Marksman', image: require('../leagueChampionPhotos/Kindred.webp') },
    { id: 61, name: 'Kled', class: 'Fighter', image: require('../leagueChampionPhotos/Kled.webp') },
    { id: 62, name: 'Kogmaw', class: 'Marksman', image: require('../leagueChampionPhotos/KogMaw.webp') },
    { id: 63, name: 'LeBlanc', class: 'Assassin', image: require('../leagueChampionPhotos/Leblanc.webp') },
    { id: 64, name: 'Lee Sin', class: 'Fighter', image: require('../leagueChampionPhotos/LeeSin.webp') },
    { id: 65, name: 'Leona', class: 'Tank', image: require('../leagueChampionPhotos/Leona.webp') },
    { id: 66, name: 'Lillia', class: 'Fighter', image: require('../leagueChampionPhotos/Lillia.webp') },
    { id: 67, name: 'Lissandra', class: 'Mage', image: require('../leagueChampionPhotos/Lissandra.webp') },
    { id: 68, name: 'Lucian', class: 'Marksman', image: require('../leagueChampionPhotos/Lucian.webp') },
    { id: 69, name: 'Lulu', class: 'Support', image: require('../leagueChampionPhotos/Lulu.webp') },
    { id: 70, name: 'Lux', class: 'Mage', image: require('../leagueChampionPhotos/Lux.webp') },
    { id: 71, name: 'Malphite', class: 'Tank', image: require('../leagueChampionPhotos/Malphite.webp') },
    { id: 72, name: 'Malzahar', class: 'Mage', image: require('../leagueChampionPhotos/Malzahar.webp') },
    { id: 73, name: 'Maokai', class: 'Tank', image: require('../leagueChampionPhotos/Maokai.webp') },
    { id: 74, name: 'Master Yi', class: 'Fighter', image: require('../leagueChampionPhotos/MasterYi.webp') },
    { id: 75, name: 'Miss Fortune', class: 'Marksman', image: require('../leagueChampionPhotos/MissFortune.webp') },
    { id: 76, name: 'Mordekaiser', class: 'Fighter', image: require('../leagueChampionPhotos/Mordekaiser.webp') },
    { id: 77, name: 'Morgana', class: 'Mage', image: require('../leagueChampionPhotos/Morgana.webp') },
    { id: 78, name: 'Nami', class: 'Support', image: require('../leagueChampionPhotos/Nami.webp') },
    { id: 79, name: 'Nasus', class: 'Tank', image: require('../leagueChampionPhotos/Nasus.webp') },
    { id: 80, name: 'Nautilus', class: 'Tank', image: require('../leagueChampionPhotos/Nautilus.webp') },
    { id: 81, name: 'Neeko', class: 'Mage', image: require('../leagueChampionPhotos/Neeko.webp') },
    { id: 82, name: 'Nidalee', class: 'Assassin', image: require('../leagueChampionPhotos/Nidalee.webp') },
    { id: 83, name: 'Nocturne', class: 'Assassin', image: require('../leagueChampionPhotos/Nocturne.webp') },
    { id: 84, name: 'Nunu & Willump', class: 'Tank', image: require('../leagueChampionPhotos/Nunu.webp') },
    { id: 85, name: 'Olaf', class: 'Fighter', image: require('../leagueChampionPhotos/Olaf.webp') },
    { id: 86, name: 'Orianna', class: 'Mage', image: require('../leagueChampionPhotos/Orianna.webp') },
    { id: 87, name: 'Ornn', class: 'Tank', image: require('../leagueChampionPhotos/Ornn.webp') },
    { id: 88, name: 'Pantheon', class: 'Fighter', image: require('../leagueChampionPhotos/Pantheon.webp') },
    { id: 89, name: 'Poppy', class: 'Tank', image: require('../leagueChampionPhotos/Poppy.webp') },
    { id: 90, name: 'Pyke', class: 'Support', image: require('../leagueChampionPhotos/Pyke.webp') },
    { id: 91, name: 'Qiyana', class: 'Assassin', image: require('../leagueChampionPhotos/Qiyana.webp') },
    { id: 92, name: 'Quinn', class: 'Marksman', image: require('../leagueChampionPhotos/Quinn.webp') },
    { id: 93, name: 'Rakan', class: 'Support', image: require('../leagueChampionPhotos/Rakan.webp') },
    { id: 94, name: 'Rammus', class: 'Tank', image: require('../leagueChampionPhotos/Rammus.webp') },
    { id: 95, name: 'Rek\'Sai', class: 'Fighter', image: require('../leagueChampionPhotos/RekSai.webp') },
    { id: 96, name: 'Rell', class: 'Tank', image: require('../leagueChampionPhotos/Rell.webp') },
    { id: 97, name: 'Renata', class: 'Support', image: require('../leagueChampionPhotos/Renata.webp') },
    { id: 98, name: 'Renekton', class: 'Fighter', image: require('../leagueChampionPhotos/Renekton.webp') },
    { id: 99, name: 'Rengar', class: 'Assassin', image: require('../leagueChampionPhotos/Rengar.webp') },
    { id: 100, name: 'Riven', class: 'Fighter', image: require('../leagueChampionPhotos/Riven.webp') },
    { id: 101, name: 'Rumble', class: 'Fighter', image: require('../leagueChampionPhotos/Rumble.webp') },
    { id: 102, name: 'Ryze', class: 'Mage', image: require('../leagueChampionPhotos/Ryze.webp') },
    { id: 103, name: 'Samira', class: 'Marksman', image: require('../leagueChampionPhotos/Samira.webp') },
    { id: 104, name: 'Sejuani', class: 'Tank', image: require('../leagueChampionPhotos/Sejuani.webp') },
    { id: 105, name: 'Senna', class: 'Marksman', image: require('../leagueChampionPhotos/Senna.webp') },
    { id: 106, name: 'Seraphine', class: 'Mage', image: require('../leagueChampionPhotos/Seraphine.webp') },
    { id: 107, name: 'Sett', class: 'Fighter', image: require('../leagueChampionPhotos/Sett.webp') },
    { id: 108, name: 'Shaco', class: 'Assassin', image: require('../leagueChampionPhotos/Shaco.webp') },
    { id: 109, name: 'Shen', class: 'Tank', image: require('../leagueChampionPhotos/Shen.webp') },
    { id: 110, name: 'Shyvana', class: 'Fighter', image: require('../leagueChampionPhotos/Shyvana.webp') },
    { id: 111, name: 'Singed', class: 'Tank', image: require('../leagueChampionPhotos/Singed.webp') },
    { id: 112, name: 'Sion', class: 'Tank', image: require('../leagueChampionPhotos/Sion.webp') },
    { id: 113, name: 'Sivir', class: 'Marksman', image: require('../leagueChampionPhotos/Sivir.webp') },
    { id: 114, name: 'Skarner', class: 'Fighter', image: require('../leagueChampionPhotos/Skarner.webp') },
    { id: 115, name: 'Sona', class: 'Support', image: require('../leagueChampionPhotos/Sona.webp') },
    { id: 116, name: 'Soraka', class: 'Support', image: require('../leagueChampionPhotos/Soraka.webp') },
    { id: 117, name: 'Swain', class: 'Mage', image: require('../leagueChampionPhotos/Swain.webp') },
    { id: 118, name: 'Sylas', class: 'Mage', image: require('../leagueChampionPhotos/Sylas.webp') },
    { id: 119, name: 'Syndra', class: 'Mage', image: require('../leagueChampionPhotos/Syndra.webp') },
    { id: 120, name: 'Tahm Kench', class: 'Tank', image: require('../leagueChampionPhotos/TahmKench.webp') },
    { id: 121, name: 'Taliyah', class: 'Mage', image: require('../leagueChampionPhotos/Taliyah.webp') },
    { id: 122, name: 'Talon', class: 'Assassin', image: require('../leagueChampionPhotos/Talon.webp') },
    { id: 123, name: 'Taric', class: 'Support', image: require('../leagueChampionPhotos/Taric.webp') },
    { id: 124, name: 'Teemo', class: 'Marksman', image: require('../leagueChampionPhotos/Teemo.webp') },
    { id: 125, name: 'Thresh', class: 'Support', image: require('../leagueChampionPhotos/Thresh.webp') },
    { id: 126, name: 'Tristana', class: 'Marksman', image: require('../leagueChampionPhotos/Tristana.webp') },
    { id: 127, name: 'Trundle', class: 'Fighter', image: require('../leagueChampionPhotos/Trundle.webp') },
    { id: 128, name: 'Tryndamere', class: 'Fighter', image: require('../leagueChampionPhotos/Tryndamere.webp') },
    { id: 129, name: 'Twisted Fate', class: 'Mage', image: require('../leagueChampionPhotos/TwistedFate.webp') },
    { id: 130, name: 'Twitch', class: 'Marksman', image: require('../leagueChampionPhotos/Twitch.webp') },
    { id: 131, name: 'Udyr', class: 'Fighter', image: require('../leagueChampionPhotos/Udyr.webp') },
    { id: 132, name: 'Urgot', class: 'Fighter', image: require('../leagueChampionPhotos/Urgot.webp') },
    { id: 133, name: 'Varus', class: 'Marksman', image: require('../leagueChampionPhotos/Varus.webp') },
    { id: 134, name: 'Vayne', class: 'Marksman', image: require('../leagueChampionPhotos/Vayne.webp') },
    { id: 135, name: 'Veigar', class: 'Mage', image: require('../leagueChampionPhotos/Veigar.webp') },
    { id: 136, name: 'Velkoz', class: 'Mage', image: require('../leagueChampionPhotos/Velkoz.webp') },
    { id: 137, name: 'Vi', class: 'Fighter', image: require('../leagueChampionPhotos/Vi.webp') },
    { id: 138, name: 'Viego', class: 'Assassin', image: require('../leagueChampionPhotos/Viego.webp') },
    { id: 139, name: 'Viktor', class: 'Mage', image: require('../leagueChampionPhotos/Viktor.webp') },
    { id: 140, name: 'Vladimir', class: 'Mage', image: require('../leagueChampionPhotos/Vladimir.webp') },
    { id: 141, name: 'Volibear', class: 'Fighter', image: require('../leagueChampionPhotos/Volibear.webp') },
    { id: 142, name: 'Warwick', class: 'Fighter', image: require('../leagueChampionPhotos/Warwick.webp') },
    { id: 143, name: 'Xayah', class: 'Marksman', image: require('../leagueChampionPhotos/Xayah.webp') },
    { id: 144, name: 'Xerath', class: 'Mage', image: require('../leagueChampionPhotos/Xerath.webp') },
    { id: 145, name: 'Xin Zhao', class: 'Fighter', image: require('../leagueChampionPhotos/XinZhao.webp') },
    { id: 146, name: 'Yasuo', class: 'Fighter', image: require('../leagueChampionPhotos/Yasuo.webp') },
    { id: 147, name: 'Yone', class: 'Fighter', image: require('../leagueChampionPhotos/Yone.webp') },
    { id: 148, name: 'Yorick', class: 'Fighter', image: require('../leagueChampionPhotos/Yorick.webp') },
    { id: 149, name: 'Yuumi', class: 'Support', image: require('../leagueChampionPhotos/Yuumi.webp') },
    { id: 150, name: 'Zac', class: 'Tank', image: require('../leagueChampionPhotos/Zac.webp') },
    { id: 151, name: 'Zed', class: 'Assassin', image: require('../leagueChampionPhotos/Zed.webp') },
    { id: 152, name: 'Zeri', class: 'Marksman', image: require('../leagueChampionPhotos/Zeri.webp') },
    { id: 153, name: 'Ziggs', class: 'Mage', image: require('../leagueChampionPhotos/Ziggs.webp') },
    { id: 154, name: 'Zilean', class: 'Support', image: require('../leagueChampionPhotos/Zilean.webp') },
    { id: 155, name: 'Zoe', class: 'Mage', image: require('../leagueChampionPhotos/Zoe.webp') },
    { id: 156, name: 'Zyra', class: 'Mage', image: require('../leagueChampionPhotos/Zyra.webp') }
];

export default baseChamps;
