import React, { useState, useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import './LegendSpinner.css'; // Assuming you have an external CSS file for styles

function shuffleArray(array) {
    let newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

const LegendSpinner = ({ initialLegends }) => {
    const [isSpinFinished, setSpinFinished] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const [selectedLegend, setSelectedLegend] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [legends, setLegends] = useState([]);
    const [legendWidth, setLegendWidth] = useState(0);
    const imageRef = useRef(null); // Ref for measuring an image

    function getLegendsMultiplier(length) {
        if (length < 50) {
            return 20;
        } else if (length >= 50 && length < 100) {
            return 10;
        } else {
            return 5;
        }
    }

    // Handle initial setup and reset when legends change
    useEffect(() => {
        const multiplier = getLegendsMultiplier(initialLegends.length);
        const doubledLegends = new Array(multiplier).fill(initialLegends).flat();
        setLegends(shuffleArray(doubledLegends));
        setIsSpinning(false);
        setSpinFinished(false);
        setSelectedLegend(null);

        // Reset the spinner's position
        const spinner = document.querySelector('.legends-strip');
        if (spinner) {
            spinner.style.transition = 'none';  // Disable transitions for the reset
            spinner.style.transform = 'translateX(0)'; // Reset transformation to start
        }

        // Measure the width of the image and update state
        if (imageRef.current) {
          const width = imageRef.current.offsetWidth; // Include padding in width if necessary
          setLegendWidth(width+10);
      }
    }, [initialLegends]); // Dependency on initialLegends means this runs when they change

    const startSpin = () => {
        setIsSpinning(true);
        setSpinFinished(false);
        setSelectedLegend(null);

        setTimeout(() => {
            const chosenIndex = Math.floor(Math.random() * legends.length);
            
            const selected = legends[chosenIndex];

            const spinnerContainer = document.querySelector('.spinner-container');
            const viewWidth = spinnerContainer.getBoundingClientRect().width;

            const centerPosition = (viewWidth / 2) - (legendWidth / 2);
            const offset = chosenIndex * legendWidth - centerPosition;

            const spinner = document.querySelector('.legends-strip');
            if (spinner) {
                spinner.style.transition = 'transform 5s cubic-bezier(0.4, 0, 0.2, 1.0)';
                spinner.style.transform = `translateX(-${offset}px)`;
            }

            setTimeout(() => {
                setIsSpinning(false);
                setSpinFinished(true);
                setSelectedLegend(selected);
                setSelectedIndex(chosenIndex)
            }, 4950);
        }, 50);
    };

    return (
        <div className="picker-container">


            <div className="start-button-container">
                <Button
                    variant="contained"
                    color={isSpinning ? "secondary" : "primary"}
                    onClick={startSpin}
                    disabled={isSpinning}
                >
                    {isSpinFinished ? "Re-Pick" : "Spin ME!"}
                </Button>
            </div>
            <div className="spinner-container">
                <div className='legends-strip'>      
                  {legends.map((legend, index) => (
                    <img 
                      ref={index === 0 ? imageRef : null} 
                      key={`${legend.id}-${index}`} 
                      src={legend.image} 
                      alt={`Legend ${legend.id}`} 
                      className={`legend-image ${selectedLegend && selectedIndex === index ? 'selected' : ''}`}
                      onLoad={index === 0 ? () => {
                        if (imageRef.current) {
                          const width = imageRef.current.offsetWidth + 10; // Add padding if necessary
                          setLegendWidth(width);
                        }
                      } : undefined} // Only attach onLoad handler to the first image
                    />
                  ))}
                </div>
            </div>
            {selectedLegend ? (
            <div className={`selected-legend`}>Your Random Legend Is: {selectedLegend.name}</div>
            ) : ''}
        </div>
    );
};

export default LegendSpinner;
