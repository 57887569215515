import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, ThemeProvider } from '@mui/material';
import ApexLegendsPage from './components/MainGames/ApexLegendsPage';
import LeagueOfLegendsPage from './components/MainGames/LeagueOfLegendsPage';
import theme from './theme';
import { Helmet } from 'react-helmet';
import './App.css';  // Ensure your CSS file is correctly imported

function App() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "VideoGame",
    "name": "Apex Legends Strategy Picker",
    "description": "A tool to randomly select strategies and legends for players of Apex Legends to enhance gameplay.",
    "applicationCategory": "WebApplication",
    "operatingSystem": "All platforms",
    "browserRequirements": "Requires JavaScript enabled",
    "softwareVersion": "1.0",
    "publisher": {
      "@type": "subject Carter Software",
      "name": "subject carter",
      "url": "https://www.legendpicker.com"
    }
  };

  return (
    <div>
      <Helmet>
        <title>Apex Legends, Legend & Challenge Picker</title>
        <meta name="description" content="This Website allows you to Randomly select a legend and a challenge for your next Apex Legends game or a random champion for League of legends. Enhance your gameplay and keep it exciting!" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-5Y7R2VTYF8"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-5Y7R2VTYF8');
          `}
        </script>
        <script type="application/ld+json">
            {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <AppBar position="static" color="primary">
            <Toolbar className="toolbar-custom">
              <Typography variant="h6" style={{ flexGrow: 1, color: theme.palette.background.default }}>
                Random Legend Picker
              </Typography>
              <Button color="inherit" component={Link} to="/">Apex Legends</Button>
              <Button color="inherit" component={Link} to="/lol">League of Legends</Button>
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path="/" element={<ApexLegendsPage />} />
            <Route path="/lol" element={<LeagueOfLegendsPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
