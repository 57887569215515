// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#357a38', // Green
    },
    secondary: {
      main: '#9E9E9E', // Grey
    },
    // Assuming you want white primarily for backgrounds or text
    background: {
      default: '#FFFFFF', // White
    },
    text: {
      primary: '#1e4620', // Dark Grey for text
      secondary: '#357a38', // Green for secondary text elements
    },
  },
  // Add any other theme customizations here
});

export default theme;
