import React, { useState } from 'react';
import { Container, Divider } from '@mui/material';
import LegendSpinner from '../LegendSpinner';
import LimitLegendsBasedOnClass from '../LimitLegendsBasedOnClass';
import baseChamps from '../../assets/characters/LeagueChamps';
import './LeagueOfLegendsPage.css';

function LeagueOfLegendsPage() {
  const [filteredChamps, setfilteredChamps] = useState(baseChamps);

  const handleClassSelectionChange = (selectedClasses) => {
      const filtered = baseChamps.filter(champ => selectedClasses[champ.class] === true);
      setfilteredChamps(filtered);
  };

  return (
      <Container>
          <Divider className="section-divider" style={{ marginBottom: 20 }}/>

          <LegendSpinner initialLegends={filteredChamps} />
          <LimitLegendsBasedOnClass 
              initialLegends={baseChamps} 
              onClassSelectionChange={handleClassSelectionChange} 
          />
          <Divider className="section-divider" style={{ marginBottom: 40 }}/>
          <Divider className="section-divider" />
      </Container>
  );
}

export default LeagueOfLegendsPage;
