import React, { useState } from 'react';
import { Typography, Container, Divider } from '@mui/material';
import LegendSpinner from '../LegendSpinner';
import LimitLegendsBasedOnClass from '../LimitLegendsBasedOnClass';
import StupidStratPicker from '../StratPicker';
import baseLegends from '../../assets/characters/ApexLegends';
import strategies from '../../assets/challenges/ApexChallenges';
import './ApexLegendsPage.css';

function ApexLegendsPage() {
    const [filteredLegends, setFilteredLegends] = useState(baseLegends);

    const handleClassSelectionChange = (selectedClasses) => {
        const filtered = baseLegends.filter(legend => selectedClasses[legend.class] === true);
        setFilteredLegends(filtered);
    };

    return (
        <Container>
            <Divider className="section-divider" style={{ marginBottom: 20 }}/>

            <LegendSpinner initialLegends={filteredLegends} />
            <LimitLegendsBasedOnClass 
                initialLegends={baseLegends} 
                onClassSelectionChange={handleClassSelectionChange} 
            />
            <Divider className="section-divider" style={{ marginBottom: 40 }}/>
            <Typography variant="h4" gutterBottom className="apex-header">
                Random Challenge Selector
            </Typography>
            <StupidStratPicker strategies={strategies} />
            <Divider className="section-divider" />
        </Container>
    );
}

export default ApexLegendsPage;
