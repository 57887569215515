const baseLegends = [
    { id: 1, name:'Ash', class:'Assault' ,image: require('../apexLegendPhotos/Ash.webp') },
    { id: 2, name:'Ballistic', class:'Assault' ,image: require('../apexLegendPhotos/Ballistic.webp') },
    { id: 3, name:'Bangalore', class:'Assault' ,image: require('../apexLegendPhotos/Bangalore.webp') },
    { id: 4, name:'Bloodhound', class:'Recon' ,image: require('../apexLegendPhotos/Bloodhound.webp') },
    { id: 5, name:'Catalyst', class:'Controller' ,image: require('../apexLegendPhotos/Catalyst.webp') },
    { id: 6, name:'Caustic', class:'Controller' ,image: require('../apexLegendPhotos/Caustic.webp') },
    { id: 7, name:'Conduit', class:'Support' ,image: require('../apexLegendPhotos/Conduit.webp') },
    { id: 8, name:'Crypto', class:'Recon' ,image: require('../apexLegendPhotos/Crypto.webp') },
    { id: 9, name:'Fuse', class:'Assault' ,image: require('../apexLegendPhotos/Fuse.webp') },
    { id: 10, name:'Gibraltar', class:'Support' ,image: require('../apexLegendPhotos/Gibraltar.webp') },
    { id: 11, name:'Horizon', class:'Skirmisher' ,image: require('../apexLegendPhotos/Horizon.webp') },
    { id: 12, name:'Lifeline', class:'Support' ,image: require('../apexLegendPhotos/Lifeline.webp') },
    { id: 13, name:'Loba', class:'Support' ,image: require('../apexLegendPhotos/Loba.webp') },
    { id: 14, name:'Mad-maggie', class:'Assault' ,image: require('../apexLegendPhotos/Mad-maggie.webp') },
    { id: 15, name:'Mirage', class:'Support' ,image: require('../apexLegendPhotos/Mirage.webp') },
    { id: 16, name:'Newcastle', class:'Support' ,image: require('../apexLegendPhotos/Newcastle.webp') },
    { id: 17, name:'Octane', class:'Skirmisher' ,image: require('../apexLegendPhotos/Octane.webp') },
    { id: 18, name:'Pathfinder', class:'Skirmisher' ,image: require('../apexLegendPhotos/Pathfinder.webp') },
    { id: 19, name:'Rampart', class:'Controller' ,image: require('../apexLegendPhotos/Rampart.webp') },
    { id: 20, name:'Revenant', class:'Skirmisher' ,image: require('../apexLegendPhotos/Revenant.webp') },
    { id: 21, name:'Seer', class:'Recon' ,image: require('../apexLegendPhotos/Seer.webp') },
    { id: 22, name:'Valkyrie', class:'Skirmisher' ,image: require('../apexLegendPhotos/Valkyrie.webp') },
    { id: 23, name:'Vantage', class:'Recon' ,image: require('../apexLegendPhotos/Vantage.webp') },
    { id: 24, name:'Wattson', class:'Controller' ,image: require('../apexLegendPhotos/Wattson.webp') },
    { id: 25, name:'Wraith', class:'Skirmisher' ,image: require('../apexLegendPhotos/Wraith.webp') },
  ];

export default baseLegends;