import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import './LimitLegendsBasedOnClass.css';

function LimitLegendsBasedOnClass({ initialLegends, onClassSelectionChange }) {
  const [availableClasses, setAvailableClasses] = useState({});
  
  useEffect(() => {
    const classMap = {};
    initialLegends.forEach(legend => {
      classMap[legend.class] = true; // Initialize each class as checked
    });
    setAvailableClasses(classMap);
  }, [initialLegends]);

  const handleClassToggle = (className) => {
    const newClasses = { ...availableClasses, [className]: !availableClasses[className] };
    setAvailableClasses(newClasses);
    onClassSelectionChange(newClasses);
  };

  const classCheckboxes = Object.keys(availableClasses).map((className) => (
    <Grid item xs={12} sm={getWidth(Object.keys(availableClasses).length)} key={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={availableClasses[className]}
            onChange={() => handleClassToggle(className)}
            name={className}
            color="primary"
          />
        }
        label={className}
        className={!availableClasses[className] ? 'inactive' : 'active'} // Apply 'inactive' only if unchecked
      />
    </Grid>
  ));

  return (
    <Grid container spacing={2} className="class-selection-container" wrap="nowrap">
      {classCheckboxes}
    </Grid>
  );
}

// Helper function to get the width for each tile based on the number of classes
function getWidth(numClasses) {
  return Math.max(Math.floor(12 / numClasses), 4);
}

export default LimitLegendsBasedOnClass;
