import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import './StratPicker.css'; 



function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // swap elements
    }
}


function StupidStratPicker({ strategies }) {
    const [selectedStrat, setSelectedStrat] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [tileClass, setTileClass] = useState('');


    const pickStrat = () => {
        shuffleArray(strategies);
        let count = 0;
        setIsSpinning(true);

        // Define the stages of interval speeds and duration
        const stages = [
            { duration: 0.1, repeats: 150 },
            { duration: 0.3, repeats: 15 },
            { duration: 0.6, repeats: 3 },
            { duration: 0.8, repeats: 2 } // Slows down significantly towards the end
        ];

        let stageIndex = 0;
        let changesInCurrentStage = 0;
        let intervalTime = stages[0].duration * 1000; // Convert seconds to milliseconds

        let interval = setInterval(() => {
            setSelectedStrat(strategies[count % strategies.length]);
            setTileClass('highlight'); // Add class to trigger animation
            count++;
            changesInCurrentStage++;

            if (changesInCurrentStage >=  (Math.random() * stages[stageIndex].repeats)) {
                stageIndex++;
                if (stageIndex >= stages.length) {
                    clearInterval(interval);
                    setIsSpinning(false);
                } else {
                    changesInCurrentStage = 0;
                    intervalTime = stages[stageIndex].duration * 1000; // Update the interval time
                    clearInterval(interval);
                    interval = setInterval(() => { // Reassign to the same variable
                        setSelectedStrat(strategies[count % strategies.length]);
                        count++;
                        changesInCurrentStage++;
                        if (changesInCurrentStage >= stages[stageIndex].repeats) {
                            stageIndex++;
                            if (stageIndex >= stages.length) {
                                clearInterval(interval);
                                
                                
                            } else {
                                changesInCurrentStage = 0;
                                intervalTime = stages[stageIndex].duration * 1000;
                                clearInterval(interval);
                                interval = setInterval(interval, intervalTime);
                                setTileClass(''); // Reset class when done // Correctly reassign the interval
                                setIsSpinning(false);
                            }
                        }
                    }, intervalTime);
                }
            }
        }, intervalTime);
        
    };

    return (
        <div style={{ marginTop: '20px' }}>
                {selectedStrat ? (
                    <Typography variant="h6" className={`strategy-tile ${tileClass}`} style={{ margin: '20px 0' }}>
                        {selectedStrat.description}
                    </Typography>
                ) : (
                    <Typography variant="h6" className="strategy-tile" style={{ margin: '20px 0' }}>
                        Spin to pick a Random challenge from 100s.
                    </Typography>
                )}
                <div className="start-button-container">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={pickStrat}
                        className={isSpinning ? 'button-spinning' : ''}
                        disabled={isSpinning}
                    >
                        {isSpinning ? "Spinning..." : "Spin"}
                    </Button>

                </div>
        </div>
    );
    

}

export default StupidStratPicker;
